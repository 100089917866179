.card.recommendations {
    // box-shadow: none;
    min-height: 276px;
    overflow-y: hidden;
    .card-body {
        .rec-container {
            margin: 15px 0 0 0;
            .rec {
                margin: 15px 0;
                padding: 6px 12px;
                display: flex;
                flex-direction: row;
                align-items: center;
                position: relative;
                top: 0;
                transition: all 0.2s ease-in-out;
                border-radius: 6px;
                cursor: pointer;
                span {
                    display: flex; 
                    align-items: center;
                    flex-direction: column;
                    width: 100px; 
                    position: relative;
                    p {
                        margin-bottom: 0;
                    }
                    img {
                        height: 50px;
                        width: 50px;
                        border-radius: 6px;
                    }
                    .rec-tag {
                        position: absolute;
                        z-index: 1;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        background-color: #fff;
                        color: #0ed7ac;
                        border-radius: 6px;
                        padding: 0 6px;
                        top: -15px;
                        right: 0;
                        margin: 0 auto;
                        @extend .custom-shadow;
                        img {
                            margin: 3px 0;
                        }
                        img,svg {
                            height: 15px;
                            width: 15px;
                            position:initial;
                        }
                    }
                }
                svg {
                    margin-top: -15px !important;
                    justify-self: center;
                }
            }
        }
        .view {
            z-index: 15;
            cursor: pointer;
            margin-top: auto;
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 6px 12px;
            height: 35px;
            background-color:#7c4aa9;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: .75;
            transition: all 0.2s ease-in-out;
            color: #fff;
            svg {
                margin-left: 5px;
            }
            &:hover {
                opacity: 1;
                @extend .custom-shadow;
                bottom: -1px;
            }
        }
    }
}