.ingredient-overview {
    font-size: 0.6em;
    .card {
        @extend .border-radius-12;
        height: 190px;
        max-height: 425px;
    }
    .row {
        margin: 0;
    }

    .starred {
        box-shadow: 0 6px 20px 0 rgba(57, 57, 57, 0.2);
        background-image: linear-gradient(to bottom, #fad388, $bg-yellow);
        width: 134px;
        justify-content: center;
        height: 200px;
    }

    .col {
        padding-right: 10px;
        padding-left: 0px;
    }

    .product {
        display: flex;
        flex-direction: row;
        width: 698px;
        height: 200px;
        box-shadow: 0 6px 20px 0 rgba(57, 57, 57, 0.2);
        background-image: linear-gradient(to bottom, #739ef9, $bg-blue);

        .chart {
            flex: 1 1 90%;
        }

        .card-title,
        .card-subtitle {
            color: #fff;
        }
    }
    .card-title {
        display: flex;
        align-items: center;
    }
    .card-body {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .fa-star {
        font-size: 22px;
    }
    .card-subtitle {
        font-size: 1rem;
        font-weight: normal;
        color: $battleship-grey;
        a {
            font-size: 0.6875rem;
            color: #fff;

            &:hover {
                color: whitesmoke;
            }

            &::after {
                content: ">>";
                margin-left: 1px;
            }
        }
    }
    .notifications {
        height: 100%;
        background-image: linear-gradient(to bottom, #e1ecf3, #cedbe9);
        // background-image: linear-gradient(to bottom, #739ef9, $bg-blue);
        color: #2c3e50;
        min-height: 220px;
        .card-body {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: flex-start;
            div {
                display: flex;
                align-items: center;
                width: 100%;
                h1 {
                    margin-right: 20px;
                }
                a {
                    margin-top: 15px;
                    margin-left: auto;
                    color: #2c3e50;
                    font-size: 14px;
                }
            }
            .account-completion {
                display: flex;
                flex-direction: column;
                .progress-bar {
                    background-color: #0ed7ac !important;
                    height: 100%;
                }
                p {
                    font-size: 14px;
                    margin: 0 auto 0 0;
                }
            }

        }
    }
    .co-info {
        box-shadow: 0 6px 20px 0 rgba(57, 57, 57, 0.2);
        border: solid 2px $powder-blue;
        background-color: #ffffff;
        width: 339px;

        .details {
            font-size: 14px;
            color: #657280;
            letter-spacing: 0.5px;
        }

        .card-subtitle {
            // border-bottom: solid 2px var(--powder-blue);
            margin: 9px 0;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            color: #273f52;
        }
        .title {
            display: flex;
            justify-content: space-between;   

            .card-title{
                width: 76px;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.5px;
                color: #273f52;
                margin-bottom: 0;
            }

          
            .logo {
                display: inline-flex;
                align-items: center;
                i {
                    font-size: 15px;
                    margin-right: 20px;

                    &.fa-circle{
                        color: var(--bg-green-light);
                    }
                }

                img {
                    width: 70px;
                    height: 25px;
                }
            }
        }
    }
    .data-tooltip {
        border-radius: 4px;
        padding: 7px 10px;
        font-size: 12px;
        background: #3a3a48;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
        color: #fff;;
        text-align: left;
        white-space: nowrap;
        margin: 5px;
    }
}
