.auth-container {
	background-color: #f4f7f9;
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	min-height: 100vh;

	form {
		width: 100%;
		display: flex;
		flex-flow: column nowrap;
		button {
			margin-top: 15px;
			align-self: center;
			background-color: #51c5ac;
			min-width: 30%;
		}
	}

	.header {
		margin-top: 4%;
		text-align: center;
		div.title {
			font-size: 1.5em;
			font-weight: bold;
			// letter-spacing: 1pt;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-evenly;
			div.logo {
				margin-bottom: 0.75rem;
				max-height: 50px;
				max-width: 66px;
				margin-right: 15px;
				img {
					max-height: 50px;
					max-width: 66px;
				}
			}
		}

		p.subtitle {
			font-weight: 500;
		}
	}

	.route-container {
		display: flex;
		margin: 5px 0 45px 0;
		width: 20%;
		a {
			color: #657280;
			width: 100%;
			text-align: center;
			margin: 0 4%;
			font-weight: bolder;
			line-height: 3;
			border-bottom: 4px solid #dce5ef;
		}
		.active {
			border-bottom: 4px solid #3266fa;
			color: #3266fa;
			display: inline-block;
		}
	}
}

.login,
.register {
	width: 35%;
	padding: 50px;
	border-radius: 20px;
	display: flex;
	align-items: center;
	justify-content: center;

	input {
		border-radius: 5px;
	}

	label {
		margin-top: 10px;
		margin-bottom: 5px;
	}

	.auth-action {
		margin-top: 20px;
		display: flex;

		a {
			margin-left: 5px;
			font-weight: bold;
			color: #3266fa;
			&::after {
				content: " >>";
			}
		}
	}
	@media screen and (max-width: 900px) {
		width: 100%;
	}
}

.register {
	width: 55%;
}

.registration-success {
	.modal-body {
		display: flex;
		flex-direction: column;
		justify-content: center !important;
		p {
			justify-self: flex-start;
		}
		.btn-awesome {
			background-color: #3f65f1 !important;
			color: #fff !important;
			border-color: #3f65f1;
			width: 100px;
			align-self: center;
		}
	}
}

.header,
.route-container {
	z-index: 2;
}
