.modal-dialog {
	&.notification-modal {
		.modal-title {
			svg:first-of-type,
			img:first-of-type {
				margin-right: 10px;
			}
		}
		&.recommendation {
			max-width: unset;
			width: 700px;
			.modal-body .col {
				padding: 0;
				.row {
					flex-wrap: nowrap;
					margin: 0;
					img {
						height: 125px;
						width: 125px;
						border-radius: 6px;
						border: 1px dashed #e1ecf3;
					}
					div.summary {
						display: flex;
						flex-direction: column;
						a {
							margin-top: auto;
							margin-bottom: 0;
						}
					}
				}
			}
			.rec-product {
				p {
					margin-bottom: 0;
				}
			}
			.action-buttons {
				display: flex;
				flex-direction: row;
				justify-content: flex-end;
				align-items: center;
			}
		}
		h6 {
			margin-bottom: 12px;
			padding: 6px;
			background-color: #e1ecf3;
			border-radius: 6px;
			width: -moz-fit-content;
			width: fit-content;
			// workaround for IE11
			display: table;
		}
		.rec-tags {
			margin-bottom: 15px;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			span {
				padding: 6px;
				border: 1px solid #e1ecf3;
				border-radius: 6px;
				margin-right: 10px;
				margin-top: 5px;
			}
		}
		.notification-detail {
			a {
				color: #3f65f1 !important;
			}
		}
		.action-status {
			margin-top: 50px;
			margin-bottom: 20px;
			h6 {
				display: inline;
			}
			.filters {
				display: inline;
				.filter-menu {
					border-radius: 6px;
					padding: 4px 6px;
				}
				.list-filters {
					border-radius: 6px;
				}
			}
		}

		.ai {
			margin-left: 1.5rem;
			background-color: #f0e9f5;
			padding: 6px 6px;
			border-radius: 6px;
			color: #7c4aa9;
			font-size: 10pt;
			display: flex;
			align-items: center;
			width: max-content;
			position: relative;
			display: inline-block;
			border: 0.005rem solid #7c4aa9;
			svg {
				margin-right: 5px;
			}
		}

		table.recommended-ingredients {
			colgroup col {
				&.score {
					width: 50px;
				}
			}
			thead {
				tr td {
					text-align: center;
				}
			}
			td {
				border-top: none;
				padding: 6px 0;
				vertical-align: middle;
				img {
					height: 24px;
					border-radius: 6px;
				}
			}
			tbody {
				tr td {
					border-bottom: 1px dashed #e1ecf3;
					text-align: center;
					&:last-of-type {
						text-align: end;
						width: max-content;
					}
					&:first-of-type {
						text-align: start;
					}
					&.rec-ingredient-name {
						text-align: start;
					}
				}
				tr:last-of-type td {
					border-bottom: none;
				}
			}
		}
	}
	&.regulation-notification {
		width: max-content;
		max-width: 1000px;
	}
}
