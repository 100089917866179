.portal-wrapper {
	width: 100%;
	background-color: #fff;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: row;
	z-index: 115;
	height: 40px;
	@extend .custom-shadow;
	position: fixed;
	h5 {
		margin-bottom: 0;
	}
	.partner-img {
		img {
			min-height: 40px;
			max-height: 40px !important;
		}
	}
}
