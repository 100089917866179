// Sidebar
$sidebar-link-padding: 15px 20px;
$sidebar-icon-size: 1.5em;
$sidebar-first-level-padding: 0 0 10px 0;
$sidebar-first-level-link-padding: 10px 15px;
$sidebar-first-level-icon-size: 14px;
$sidebar-second-level-padding: 0 0 0px 20px;
/*******************
Main sidebar
******************/

.left-sidebar {
	position: relative;
	width: $sidebar-width-full;
	height: 100%;
	top: 0px;
	z-index: 100;
	background: $sidebar;
	box-shadow: $shadow;
	transition: 0.2s ease-in;
	letter-spacing: normal;
}

.left-submenu {
	position: relative;
	width: 195px;
	height: 100%;
	top: 0px;
	left: 100px;
	z-index: 100;
	padding: 18px;
	padding-top: 220px;
	background-color: #3f65f1;
	// background: $sidebar;
	// box-shadow: $shadow;
	transition: 0.5s ease-in;
	letter-spacing: normal;
}

.notification-bubble {
	margin-left: -40px;
	margin-top: -10px;
	position: absolute;
	margin-right: auto;
	background-color: #e85a73;
	border-radius: 50%; /* one half of ( (border * 2) + height + padding ) */
	color: white;
	height: 12px;
	width: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
}

/*******************
sidebar navigation
******************/
.scroll-sidebar {
	height: calc(100% - 100px);
	overflow: auto;
}

.sidebar-nav {
	.sidebar-loader {
		text-align: center;
	}
	ul {
		display: flex;
		justify-content: center;
		.sidebar-item {
			// width: $sidebar-width-full;
			width: 85%;
			border-radius: 6px;
			margin: 5px 0;
			.sidebar-link {
				color: #fff;
				padding: $sidebar-link-padding;
				display: flex;
				flex-direction: column;
				white-space: nowrap;
				align-items: center;
				line-height: 25px;
				opacity: 0.9;
				cursor: pointer;
				i {
					font-style: normal;
					width: 30px;
					line-height: 25px;
					font-size: 24px;
					color: #fff;
					display: inline-block;
					text-align: center;
				}
				svg {
					color: #fff !important;
				}
				span {
					font-size: 11px;
					color: #fff;
					margin-top: 10px;
					line-height: 12px;
				}
				&.active,
				&:hover {
					opacity: 1;
					// Applying colors based on icon, definitions in _variables.scss
					i,
					svg {
						// color: $sidebar-icons-active !important;
						color: $sidebar-icons-active !important;
					}
					&.dashboard {
						i,
						svg {
							// color: $sidebar-icons-active !important;
							color: $sidebar-dashboard-active !important;
						}
					}
					&.ingredients {
						i,
						svg {
							color: $sidebar-ingredients-active !important;
						}
					}
					&.products {
						i,
						svg {
							color: $sidebar-products-active !important;
						}
					}
					&.packaging {
						i,
						svg {
							// color: $sidebar-packaging-active !important;
							color: #fff !important;
						}
					}
					&.personas {
						i,
						svg {
							color: $sidebar-personas-active !important;
						}
					}
					&.analytics {
						i,
						svg {
							color: $sidebar-analytics-active !important;
						}
					}
					&.integrations {
						i,
						svg {
							color: $sidebar-integrations-active !important;
						}
					}
					span {
						color: whitesmoke;
					}
				}
			}
			&.selected > .sidebar-link,
			&:hover > .sidebar-link {
				/*background: rgba(0, 0, 0, 0.04);*/
				opacity: 1;
			}
			/*******************
           First level
           ******************/
			.first-level {
				padding: $sidebar-first-level-padding;
				.sidebar-item.active .sidebar-link {
					opacity: 1;
				}
				.sidebar-item .sidebar-link {
					padding: $sidebar-first-level-link-padding;
					i {
						font-size: $sidebar-first-level-icon-size;
						visibility: hidden;
					}
				}
			}
			.second-level .sidebar-item {
				padding: $sidebar-second-level-padding;
			}
		}
		/*******************
         Small cap
         ******************/
		.nav-small-cap {
			font-size: 12px;
			padding: $sidebar-link-padding;
			white-space: nowrap;
			display: flex;
			align-items: center;
			line-height: 30px;
			margin-top: 10px;
			color: $sidebar-text;
			opacity: 1;
			text-transform: uppercase;
			i {
				line-height: 30px;
				margin: 0 5px;
			}
		}
	}
}
/*******************
 common m-p removed
******************/
.sidebar-nav {
	ul {
		margin: 0px;
		padding: 0px;
		li {
			list-style: none;
		}
	}
}
.collapse.in {
	display: block;
}
/*******************
Down arrow
******************/
.sidebar-nav .has-arrow {
	position: relative;
	&::after {
		position: absolute;
		content: "";
		width: 7px;
		height: 7px;
		border-width: 1px 0 0 1px;
		border-style: solid;
		border-color: $sidebar-text;
		margin-left: 10px;
		-webkit-transform: rotate(135deg) translate(0, -50%);
		-ms-transform: rotate(135deg) translate(0, -50%);
		-o-transform: rotate(135deg) translate(0, -50%);
		transform: rotate(135deg) translate(0, -50%);
		-webkit-transform-origin: top;
		-ms-transform-origin: top;
		-o-transform-origin: top;
		transform-origin: top;
		top: 26px;
		right: 15px;
		-webkit-transition: all 0.3s ease-out;
		-o-transition: all 0.3s ease-out;
		transition: all 0.3s ease-out;
	}
}

/*.sidebar-nav .active > .has-arrow::after,*/

/*.sidebar-nav li.selected > .has-arrow::after,*/
.sidebar-nav li.active > .has-arrow::after,
.sidebar-nav li > .has-arrow.active::after,
.sidebar-nav .has-arrow[aria-expanded="true"]::after {
	-webkit-transform: rotate(-135deg) translate(0, -50%);
	-ms-transform: rotate(-135deg) translate(0, -50%);
	-o-transform: rotate(-135deg) translate(0, -50%);
	transform: rotate(-135deg) translate(0, -50%);
}

/*******************
User Profile
******************/
.user-profile {
	padding: 15px;

	.user-content,
	.user-name,
	.user-email {
		color: $theme-light;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}
.create-btn {
	height: 40px;
	background: $dark;
}
