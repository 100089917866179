//custom fonts
@font-face {
  font-family: Euclid Circular A;
  src: url(../../fonts/typefaces/EuclidCircularA-Regular_1.woff);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Euclid Circluar A;
  src: url(../../fonts/typefaces/EuclidCircularA-Semibold_1.woff);
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Euclid Circluar A;
  src: url(../../fonts/typefaces/EuclidCircularA-Bold_1.woff);
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'icon';
  src:  url('../../fonts/icon.eot?triajt');
  src:  url('../../fonts/icon.eot?triajt#iefix') format('embedded-opentype'),
    url('../../fonts/icon.ttf?triajt') format('truetype'),
    url('../../fonts/icon.woff?triajt') format('woff'),
    url('../../fonts/icon.svg?triajt#icon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'icomoon';
  src:  url('../../fonts/icomoon.eot?3jcrey');
  src:  url('../../fonts/icomoon.eot?3jcrey#iefix') format('embedded-opentype'),
    url('../../fonts/icomoon.ttf?3jcrey') format('truetype'),
    url('../../fonts/icomoon.woff?3jcrey') format('woff'),
    url('../../fonts/icomoon.svg?3jcrey#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icon' !important;
  // speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-fruit:before {
  content: "\e900";
}
.icon-nav-dashboard:before {
  content: "\e901";
}
.icon-nav-ingredients:before {
  content: "\e902";
}
.icon-icon-nav-packaging:before {
  content: "\e900";
}
.icon-crown{
  position: absolute;
  z-index: 10;
  margin-top: -13px;
  margin-left: 45px;
  height: 16px;
  width: 16px
}




[class^="icon-icon"], [class*=" icon-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}