.feature-table {
    thead > tr > th  {
        border: none;
    }
    tbody {
        tr {
            &.striped {
                td {
                    background-color: #e1ecf380;
                }
            }
            &.sub-header {
                font-weight: 900;
                font-size: 12pt;
                td{
                    background-color: transparent !important;
                }
            }
         }
         td {
            border: none;
            padding: .75rem;
            text-align: center;
            &:first-of-type {
                 border-radius: 6px 0 0 6px; 
                 text-align: start;
            }
            &:last-of-type {
                 border-radius: 0 6px 6px 0; 
            }
            svg {
                height: 16px;
                color: #3f65f1;
            }
        } 
    }

}